<template>
    <div>
        <header class="header mo_header" style="display: none">
            <div class="header_box">
                <div class="logo">
                    <a href="#">
                        <h2>회원가입</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="mo_header_close_btn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 6.2225L14.2225 0L16 1.7775L9.7775 8L16 14.2225L14.2225 16L8 9.7775L1.7775 16L0 14.2225L6.2225 8L0 1.7775L1.7775 0L8 6.2225Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </header>
        <section class="login_main">
            <div class="login_main_box join_box">
                <img src="/media/img/join_1_icon.png" alt="" />
                <h2>가입완료</h2>
                <h3 class="join_sub_title">회원가입을 축하드립니다!</h3>
                <h3 class="join_sub_title">3일간 자유롭게 이용해보세요</h3>
                <button @click="checkSpace">플레이어 바로가기</button>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from 'axios';

export default defineComponent({
  props: ['backStatus'],

  beforeMount () {
    if (this.$store.getters.isLogin) {
      this.$store.commit('setSpaceId', '');
      history.go(1);
    }
  },

  mounted () {
    // if (this.backStatus !== 'Y') {
    // router.replace({ name: 'Login' });
    // }
  },

  methods: {
    checkSpace () {
      var email = VueCookies.get('email');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      axios.post('/api/user/getLoginUser', { email }, { headers }).then((response) => {
        var userId = response.data.result.userId;
        var email = response.data.result.email;
        var comeFrom = response.data.result.comeFrom;
        var userType = response.data.result.userType;
        var signupCouponAt = response.data.result.signupCouponAt;
        var division = response.data.result.division;
        // 쿠키 셋팅

        if (VueCookies.get('autoLogin') === 'S') {
          VueCookies.set('autoLogin', 'Y');
        }
        VueCookies.set('userId', userId);
        VueCookies.set('comeFrom', comeFrom);
        VueCookies.set('email', email);
        VueCookies.set('userType', userType);
        this.$store.commit('setEmail', email);
        this.$store.commit('setSignupCouponAt', signupCouponAt);
        this.$store.commit('setDivision', division);

        var spaceId = VueCookies.get('spaceId');

        if (spaceId != null) {
          axios
            .post('/api/profile/getMySpaceInfoCheck', { spaceId, userId }, { headers })
            .then((res2) => {
              if (res2.data.result) {
                // 이전 접속한 계정과 동일 계정이 접속
                axios
                  .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                  .then((res4) => {
                    if (res4.data.result.spacePayAt === 'Y' || res4.data.result.spacePayAt === 'T') {
                      // 이전 접속한 계정과 동일 계정일 경우 구독여부 체크
                      router.replace({ name: 'Player', params: { spaceId: spaceId } }); // 구독중
                    } else {
                      // 구독 중이 아닐 경우 해당 계정에 spaceId 유무 체크
                      // 쿠키에 저장된 spaceId 삭제
                      VueCookies.remove('spaceId');
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then((res5) => {
                          if (res5.data.resultCd === '0000') {
                            const result = res5.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter((e) => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              // 보유 스페이스가 없거나 전부 S인 경우
                              router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                            } else {
                              router.push({ name: 'MySpace' });
                            }
                          }
                        })
                        .catch((err5) => {
                          console.log(err5);
                        });
                    }
                  })
                  .catch((err4) => {
                    console.log(err4);
                  });
              } else {
                // 이전 접속한 계정과 다른 계정이 접속
                VueCookies.remove('spaceId');
                axios
                  .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                  .then((response2) => {
                    if (response2.data.result.length > 1) {
                      // 구독중 spaceId가 2개 이상인 경우
                      router.push({ name: 'SpaceChoice' });
                    } else if (response2.data.result.length === 1) {
                      // 구독중 spaceId가 1개인 경우
                      VueCookies.set('spaceId', response2.data.result[0].spaceId);
                      router.push({ name: 'Player' });
                    }
                  })
                  .catch((error2) => {
                    var resultCd = error2.response.data.resultCd;
                    if (resultCd === 'D0001') {
                      // 구독중인 스페이스가 없는 경우
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then((response3) => {
                          if (response3.data.resultCd === '0000') {
                            const result = response3.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter((e) => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              // 보유 스페이스가 없거나 전부 S인 경우
                              router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                            } else {
                              router.push({ name: 'MySpace' });
                            }
                          }
                        })
                        .catch((error3) => {
                          console.log(error3);
                        });
                    }
                  });
              }
            })
            .catch((err2) => {
              console.log(err2);
            });
        } else {
          axios
            .post('/api/profile/subscribeSpaceList', { userId }, { headers })
            .then((response2) => {
              if (response2.data.result.length > 1) {
                // 구독중 spaceId가 2개 이상인 경우
                router.push({ name: 'SpaceChoice' });
              } else if (response2.data.result.length === 1) {
                // 구독중 spaceId가 1개인 경우
                VueCookies.set('spaceId', response2.data.result[0].spaceId);
                router.push({ name: 'Player' });
              }
            })
            .catch((error2) => {
              var resultCd = error2.response.data.resultCd;
              if (resultCd === 'D0001') {
                // 구독중인 스페이스가 없는 경우
                axios
                  .post('/api/profile/checkSpace', { userId }, { headers })
                  .then((response3) => {
                    if (response3.data.resultCd === '0000') {
                      const result = response3.data.result;
                      let sCount = -1;
                      if (result.length > 0) sCount = result.filter((e) => e === 'S').length;
                      if (sCount === -1 || sCount === result.length) {
                        // 보유 스페이스가 없거나 전부 S인 경우
                        router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                      } else {
                        router.push({ name: 'MySpace' });
                      }
                    }
                  })
                  .catch((error3) => {
                    console.log(error3);
                  });
              }
            });
        }
      });
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/social.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
.join_sub_title {
  color: var(--whitefff);
  font-size: 24px;
  font-weight: 500;
  text-align: center;

  line-height: 35px !important;
}
</style>
